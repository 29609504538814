<template>
    <div>
        <form method="post" class="grid grid-cols-12 gap-6" @submit.prevent="save">
            <div class="relative col-span-6">
                <label for="form-title" class="form-label">
                    Заголовок
                    <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
                </label>
                <input
                    id="form-title"
                    v-model="form.title"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.title.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6">
                <label for="form-index" class="form-label">
                    Порядковый номер
                    <sup v-if="v$.form.index.required" class="text-theme-6">*</sup>
                </label>
                <input
                    id="form-index"
                    v-model="form.index"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.index.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-12">
                <label class="form-label">
                    Изображение для ПК
                    <sup v-if="v$.form.image.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.image.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.image.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.image"
                    :image="imagePreview"
                    recommended-sizes="1920x800px"
                    :has-error="!!v$.form.image.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-12">
                <label class="form-label">
                    Изображение для планшетов
                    <sup v-if="v$.form.tablet_image.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.tablet_image.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.tablet_image.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.tablet_image"
                    :image="tabletImagePreview"
                    recommended-sizes="768x1024px"
                    :has-error="!!v$.form.tablet_image.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-12">
                <label class="form-label">
                    Мобильное изображение
                    <sup v-if="v$.form.mobile_image.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.mobile_image.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.mobile_image.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.mobile_image"
                    :image="mobileImagePreview"
                    recommended-sizes="430x270px"
                    :has-error="!!v$.form.mobile_image.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="col-span-6 relative">
                <label for="form-birth" class="form-label">Дата</label>
                <input id="form-birth" v-model="form.date" type="date" class="form-control w-full" />
            </div>

            <div class="col-span-6">
                <label for="form-published" class="form-label">Опубликовано</label>
                <select id="form-published" v-model="form.published" class="form-select form-select-lg">
                    <option value="1">Опубликовано</option>
                    <option value="0">Не опубликовано</option>
                </select>
            </div>

            <div class="col-span-12 flex items-center justify-end mt-5">
                <button type="submit" class="btn btn-primary mb-2">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'SlideEdit',
    components: { Preloader, CustomWyswig, UploadImageNew },
    props: {
        item: Object,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                index: { required },
                image: { required },
                tablet_image: { required },
                mobile_image: { required },
            },
        };
    },
    data() {
        return {
            form: {
                id: null,
                title: null,
                subtitle: null,
                image: null,
                mobile_image: null,
                tablet_image: null,
                mobile_image_delete: false,
                tablet_image_delete: false,
                text: null,
                button: false,
                link: null,
                date: null,
                index: null,
                published: null,
            },
            imagePreview: null,
            tabletImagePreview: null,
            mobileImagePreview: null,
        };
    },
    created() {
        if (this.item) {
            for (const field in this.form) {
                if (this.item[field] !== 'undefined') {
                    this.form[field] = this.item[field];
                }
            }
        }
    },
    methods: {
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            window.URL.revokeObjectURL(this.previewImage);
            window.URL.revokeObjectURL(this.tabletImagePreview);
            window.URL.revokeObjectURL(this.mobileImagePreview);
            this.$emit('save', this.form);
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped lang="scss">
form {
    padding: 0 10px;
}

.radio-wrap {
    border-radius: 0.375rem 0 0 0.375rem;
    border: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.group-title {
    padding-bottom: 10px;
}

.radio-group {
    padding-bottom: 15px;
    padding-left: 10px;

    .form-control,
    .form-select {
        border-radius: 0 0.375rem 0.375rem 0;

        &:focus {
            box-shadow: none;
        }
    }
}
</style>
